import React from 'react';

import { useLifestyleCategories } from '../../../../lifestyles/hooks/useLifestyleCategories';

import { ThreeDStockIndexPageLifestylesCategoriesNavBody } from './components/ThreeDStockIndexPageLifestylesCategoriesNavBody';

import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../helpers/AlertMessage';

function ThreeDStockIndexPageLifestylesCategoriesNav() {
  const {
    lifestyleCategories,
    lifestyleCategoriesErrorMessage,
    lifestyleCategoriesFetched,
    lifestyleCategoriesIsPlaceholderData
  } = useLifestyleCategories();

  return (
    <div className="p-4">
      <AlertMessage message={lifestyleCategoriesErrorMessage} />
      <LoadingSkeleton
        loaded={
          lifestyleCategoriesIsPlaceholderData || lifestyleCategoriesFetched
        }
      >
        <ThreeDStockIndexPageLifestylesCategoriesNavBody
          lifestyleCategories={lifestyleCategories}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default ThreeDStockIndexPageLifestylesCategoriesNav;
