import {
  ProductCategoryID,
  ProductUUID,
  ProductClientID
} from './productsTypes';

export class ProductCache {
  static indexCacheKey() {
    return 'products';
  }

  static showCacheKey() {
    return 'product';
  }

  static categoriesCacheKey() {
    return 'productCategories';
  }

  static categoriesLibraryCacheKey(clientId: ProductClientID) {
    return `productCategoriesLibrary-${clientId}`;
  }

  static libraryCacheKey(clientId: ProductClientID) {
    return `productsLibrary-${clientId}`;
  }

  static categoryCacheKey(id: ProductCategoryID) {
    return `productCategories-${id}`;
  }

  static stylesFilterCacheKey() {
    return 'productStylesFilter';
  }

  static stylesLibraryFilterCacheKey(clientId: ProductClientID) {
    return `productStylesLibraryFilter-${clientId}`;
  }

  static materialsFilterCacheKey() {
    return 'productMaterialsFilter';
  }

  static materialsLibraryFilterCacheKey(clientId: ProductClientID) {
    return `productMaterialsLibraryFilter-${clientId}`;
  }

  static colorsFilterCacheKey() {
    return 'productColorsFilter';
  }

  static colorsLibraryFilterCacheKey(clientId: ProductClientID) {
    return `productColorsLibraryFilter-${clientId}`;
  }

  static brandsFilterCacheKey(categoryId?: ProductCategoryID) {
    return `productBrandsFilter${categoryId ? `-category-${categoryId}` : ''}`;
  }

  static brandsLibraryFilterCacheKey(
    clientId: ProductClientID,
    categoryId?: ProductCategoryID
  ) {
    return `productBrandsLibraryFilter-${clientId}${
      categoryId ? `-category-${categoryId}` : ''
    }`;
  }

  static brandsSelectedFilterCacheKey() {
    return 'productBrandsSelectedFilter';
  }

  static showQueryKey(productUuid: ProductUUID) {
    return ['product', productUuid];
  }
}
