import { ID, UUID, CreatedAt, UpdatedAt } from '../../../types';

export type PermissionTypeOptions =
  | 'create'
  | 'delete'
  | 'download'
  | 'index'
  | 'read'
  | 'update';

export interface PermissionRoleType {
  id: ID;
  type: string;
  name: string;
}

export type PermissionID = ID;
export type PermissionUUID = UUID;
export type PermissionDescription = string;
export type PermissionCreatedAt = CreatedAt;
export type PermissionUpdatedAt = UpdatedAt;
export type PermissionAction = string;
export type PermissionAndroid = boolean;
export type PermissionDanger = boolean;
export type PermissionFrontend = boolean;
export type PermissionIos = boolean;
export type PermissionRoles = PermissionRoleType[];
export type PermissionRoleIds = ID[];

export const enum PermissionFields {
  ID = 'id',
  UUID = 'uuid',
  TYPE = 'type',
  DESCRIPTION = 'description',
  CREATED_AT = 'createdAt',
  ACTION = 'action',
  ANDROID = 'android',
  DANGER = 'danger',
  FRONTEND = 'frontend',
  ROLE_IDS = 'roleIds',
  IOS = 'ios'
}

export interface PermissionType {
  action?: PermissionAction;
  android?: PermissionAndroid;
  danger?: PermissionDanger;
  frontend?: PermissionFrontend;
  ios?: PermissionIos;
  roleIds?: PermissionRoleIds;
  type?: PermissionTypeOptions;
  description?: PermissionDescription;
  uuid: PermissionUUID;
  roles?: PermissionRoleType[];
}

export type PermissionsType = PermissionType[];

export const enum PermissionsSortEnum {
  ACTION_ASC = 'ACTION_ASC',
  ACTION_DESC = 'ACTION_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  TYPE_ASC = 'TYPE_ASC',
  TYPE_DESC = 'TYPE_DESC',
  UUID_ASC = 'UUID_ASC',
  UUID_DESC = 'UUID_DESC'
}
