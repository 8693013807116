import React from 'react';

import { useProductCategories } from '../../../../products/hooks/useProductCategories';

import { ThreeDStockIndexPageProductsCategoriesNavBody } from './components/ThreeDStockIndexPageProductsCategoriesNavBody';

import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../helpers/AlertMessage';

function ThreeDStockIndexPageProductsCategoriesNav() {
  const {
    productCategories,
    productCategoriesErrorMessage,
    productCategoriesFetched,
    productCategoriesIsPlaceholderData
  } = useProductCategories();

  return (
    <div className="p-4">
      <AlertMessage message={productCategoriesErrorMessage} />
      <LoadingSkeleton
        loaded={productCategoriesIsPlaceholderData || productCategoriesFetched}
      >
        <ThreeDStockIndexPageProductsCategoriesNavBody
          productCategories={productCategories}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default ThreeDStockIndexPageProductsCategoriesNav;
