import compact from 'lodash/compact';

import { CategoryID } from '../../../categories/categoriesTypes';

import { FetchLifestylesFilters } from '../../lifestylesTypes';

interface GetChangeLifestylesFiltersCategoriesDataCategoryType {
  id: CategoryID;
  parent?: {
    id: CategoryID;
  };
}

interface GetChangeLifestylesFiltersCategoriesDataOptions {
  lifestyleCategory: GetChangeLifestylesFiltersCategoriesDataCategoryType | null;
}

interface GetChangeLifestylesFiltersCategoriesDataReturn {
  changeFilters: FetchLifestylesFilters;
  resetFilters: string[];
}

function getChangeLifestylesFiltersCategoriesData({
  lifestyleCategory
}: GetChangeLifestylesFiltersCategoriesDataOptions): GetChangeLifestylesFiltersCategoriesDataReturn {
  const parentCategoryId =
    lifestyleCategory?.parent?.id || lifestyleCategory?.id;
  const childCategoryId = lifestyleCategory?.parent?.id
    ? lifestyleCategory?.id
    : null;

  const changeFilters: FetchLifestylesFilters = {
    ...(parentCategoryId
      ? { lifestyleParentCategoryIds: [parentCategoryId as string] }
      : {}),
    ...(childCategoryId
      ? { lifestyleCategoryId: { in: [childCategoryId as string] } }
      : {})
  };

  const resetFilters = compact([
    childCategoryId ? null : 'lifestyleCategoryId',
    parentCategoryId ? null : 'lifestyleParentCategoryIds'
  ]);

  return {
    changeFilters,
    resetFilters
  };
}

export default getChangeLifestylesFiltersCategoriesData;
