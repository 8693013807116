import React, { Fragment } from 'react';

import { ThreeDStockIndexPageLifestylesCategoriesNavCategory } from '../../ThreeDStockIndexPageLifestylesCategoriesNav.types';

import { ThreeDStockIndexPageLifestylesCategoriesNavHead } from '../ThreeDStockIndexPageLifestylesCategoriesNavHead';
import { ThreeDStockIndexPageLifestylesCategoriesNavList } from '../ThreeDStockIndexPageLifestylesCategoriesNavList';

interface ThreeDStockIndexPageLifestylesCategoriesNavBodyProps {
  lifestyleCategories: ThreeDStockIndexPageLifestylesCategoriesNavCategory[];
}

function ThreeDStockIndexPageLifestylesCategoriesNavBody({
  lifestyleCategories
}: ThreeDStockIndexPageLifestylesCategoriesNavBodyProps) {
  return (
    <Fragment>
      <ThreeDStockIndexPageLifestylesCategoriesNavHead />
      <ThreeDStockIndexPageLifestylesCategoriesNavList
        lifestyleCategories={lifestyleCategories}
      />
    </Fragment>
  );
}

export default ThreeDStockIndexPageLifestylesCategoriesNavBody;
