import React from 'react';

import { SecondaryMenuWrapper } from '../../../../../app/components/menus/SecondaryMenuWrapper';
import { ThreeDStockIndexPageLibraryNav } from '../ThreeDStockIndexPageLibraryNav';
import { ThreeDStockIndexPageProductsCategoriesNav } from '../ThreeDStockIndexPageProductsCategoriesNav';
import { ThreeDStockIndexPageLifestylesCategoriesNav } from '../ThreeDStockIndexPageLifestylesCategoriesNav';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { StockPermissions } from '../../../stockConstants';

import { words } from '../../../../../locales/keys';

function ThreeDStockIndexPageMenu() {
  return (
    <SecondaryMenuWrapper i18nTitle={words.threeDStock}>
      <CheckPermissions
        action={StockPermissions.READ_STOCK_MENU_LIBRARY_SECTION}
      >
        <ThreeDStockIndexPageLibraryNav />
      </CheckPermissions>
      <ThreeDStockIndexPageProductsCategoriesNav />
      <ThreeDStockIndexPageLifestylesCategoriesNav />
    </SecondaryMenuWrapper>
  );
}

export default ThreeDStockIndexPageMenu;
