import React from 'react';

import { ThreeDStockIndexPageLibraryNavItem } from './components/ThreeDStockIndexPageLibraryNavItem';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { Translate } from '../../../../../helpers/Translate';

import { ProductPath } from '../../../../products/ProductPath';
import { LifestylePath } from '../../../../lifestyles/LifestylePath';

import { StockPermissions } from '../../../stockConstants';

import {
  words,
  productsKeys,
  lifestylesKeys
} from '../../../../../locales/keys';

function ThreeDStockIndexPageLifestylesCategoriesNav() {
  return (
    <div className="p-4">
      <div className="-mx-4 mb-1">
        <div className="flex items-center group relative px-4 py-2">
          <h6 className="flex-1 font-semibold uppercase px-2">
            <Translate id={words.myLibrary} />
          </h6>
        </div>
      </div>
      <CheckPermissions
        action={StockPermissions.READ_STOCK_MENU_PRODUCTS_LIBRARY_LINK}
      >
        <ThreeDStockIndexPageLibraryNavItem
          href={ProductPath.libraryIndex()}
          i18nText={productsKeys.my}
        />
      </CheckPermissions>
      <CheckPermissions
        action={StockPermissions.READ_STOCK_MENU_LIFESTYLES_LIBRARY_LINK}
      >
        <ThreeDStockIndexPageLibraryNavItem
          href={LifestylePath.libraryIndex()}
          i18nText={lifestylesKeys.my}
        />
      </CheckPermissions>
    </div>
  );
}

export default ThreeDStockIndexPageLifestylesCategoriesNav;
