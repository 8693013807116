import compact from 'lodash/compact';

import { CategoryID } from '../../../categories/categoriesTypes';

import { FetchProductsFilters } from '../../productsTypes';

interface GetChangeProductsFiltersCategoriesDataCategoryType {
  id: CategoryID;
  parent?: {
    id: CategoryID;
  };
}

interface GetChangeProductsFiltersCategoriesDataOptions {
  productCategory: GetChangeProductsFiltersCategoriesDataCategoryType | null;
}

interface GetChangeProductsFiltersCategoriesDataReturn {
  changeFilters: FetchProductsFilters;
  resetFilters: string[];
}

function getChangeProductsFiltersCategoriesData({
  productCategory
}: GetChangeProductsFiltersCategoriesDataOptions): GetChangeProductsFiltersCategoriesDataReturn {
  const parentCategoryId = productCategory?.parent?.id || productCategory?.id;
  const childCategoryId = productCategory?.parent?.id
    ? productCategory?.id
    : null;

  const changeFilters: FetchProductsFilters = {
    ...(parentCategoryId
      ? { productParentCategoryIds: [parentCategoryId as string] }
      : {}),
    ...(childCategoryId
      ? { productCategoryId: { in: [childCategoryId as string] } }
      : {})
  };

  const resetFilters = compact([
    childCategoryId ? null : 'productCategoryId',
    parentCategoryId ? null : 'productParentCategoryIds'
  ]);

  return {
    changeFilters,
    resetFilters
  };
}

export default getChangeProductsFiltersCategoriesData;
