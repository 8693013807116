import React from 'react';
import { NextLinkHelper } from '../../../../../../../helpers/links/NextLinkHelper';

import { Translate } from '../../../../../../../helpers/Translate';
import { lifestylesKeys, words } from '../../../../../../../locales/keys';

import { LifestylePath } from '../../../../../../lifestyles/LifestylePath';

function ThreeDStockIndexPageLifestylesCategoriesNavHead() {
  return (
    <div className="-mx-4 mb-1">
      <div className="flex items-center group relative px-4 py-2 transition-colors ease-in-out duration-150 focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100 bg-gray-500 bg-opacity-0 hover:bg-opacity-20">
        <h6 className="flex-1 font-semibold uppercase px-2">
          <Translate id={lifestylesKeys.plural} />
        </h6>
        <NextLinkHelper
          href={LifestylePath.index()}
          className="opacity-0 group-hover:opacity-100 focus:opacity-100 focus:outline-none hover:underline text-sm"
        >
          <Translate id={words.viewAll} />
          <span className="absolute inset-0" aria-hidden="true" />
        </NextLinkHelper>
      </div>
    </div>
  );
}

export default ThreeDStockIndexPageLifestylesCategoriesNavHead;
