import startsWith from 'lodash/startsWith';

import { MenuDropdownWithLinksItemData } from '../MenuDropdownWithLinks';

import { BillPath } from '../../../../bills/BillPath';
import { PagePath } from '../../../../pages/PagePath';
import { PermissionPath } from '../../../../permissions/PermissionPath';
import { ReportPath } from '../../../../reports/ReportPath';
import { RolePath } from '../../../../roles/RolePath';
import { SectionPath } from '../../../../sections/SectionPath';
import { SentMessagePath } from '../../../../sentMessages/SentMessagePath';

import { BillsPermissions } from '../../../../bills/billsConstants';
import { PagesPermissions } from '../../../../pages/pagesConstants';
import { PermissionsPermissions } from '../../../../permissions/permissionsConstants';
import { ReportsPermissions } from '../../../../reports/reportsConstants';
import { RolesPermissions } from '../../../../roles/rolesConstants';
import { SectionsPermissions } from '../../../../sections/sectionsConstants';
import { SentMessagesPermissions } from '../../../../sentMessages/sentMessagesConstants';

import { menuKeys } from '../../../../../locales/keys';

export const accessDropdownItemsData: MenuDropdownWithLinksItemData[] = [
  {
    href: RolePath.index(),
    permission: RolesPermissions.READ_ADMIN_MENU_ROLES_LINK,
    i18nText: menuKeys.links.roles,
    isActive: (pathName) => startsWith(pathName, RolePath.index())
  },
  {
    href: PermissionPath.index(),
    permission: PermissionsPermissions.READ_ADMIN_MENU_PERMISSIONS_LINK,
    i18nText: menuKeys.links.permissions,
    isActive: (pathName) => startsWith(pathName, PermissionPath.index())
  }
];

export const financeDropdownItemsData: MenuDropdownWithLinksItemData[] = [
  {
    href: BillPath.index(),
    permission: BillsPermissions.READ_ADMIN_MENU_INVOICES_LINK,
    i18nText: menuKeys.links.invoices,
    isActive: (pathName) => startsWith(pathName, BillPath.index())
  }
];

export const contentDropdownItemsData: MenuDropdownWithLinksItemData[] = [
  {
    href: PagePath.index(),
    permission: PagesPermissions.READ_ADMIN_MENU_PAGES_LINK,
    i18nText: menuKeys.links.pages,
    isActive: (pathName) => startsWith(pathName, PagePath.index())
  },
  {
    href: SectionPath.index(),
    permission: SectionsPermissions.READ_ADMIN_MENU_SECTIONS_LINK,
    i18nText: menuKeys.links.sections,
    isActive: (pathName) => startsWith(pathName, SectionPath.index())
  }
];

export const reportsDropdownItemsData: MenuDropdownWithLinksItemData[] = [
  {
    href: ReportPath.index(),
    permission: ReportsPermissions.READ_ADMIN_MENU_REPORTS_LINK,
    i18nText: menuKeys.links.reports,
    isActive: (pathName) => startsWith(pathName, ReportPath.index())
  },
  {
    href: SentMessagePath.index(),
    permission: SentMessagesPermissions.READ_ADMIN_MENU_SENT_MESSAGES_LINK,
    i18nText: menuKeys.links.sentMessages,
    isActive: (pathName) => startsWith(pathName, SentMessagePath.index())
  }
];

export const accessPermissions = accessDropdownItemsData.map(
  (accessDropdownItem) => accessDropdownItem.permission
);

export const financePermissions = financeDropdownItemsData.map(
  (financeDropdownItem) => financeDropdownItem.permission
);

export const contentPermissions = contentDropdownItemsData.map(
  (contentDropdownItem) => contentDropdownItem.permission
);

export const reportsPermissions = reportsDropdownItemsData.map(
  (reportsDropdownItem) => reportsDropdownItem.permission
);
