import { LifestyleClientID } from '../../lifestylesTypes';

import { useIndexQuery } from '../../../common/hooks/base/reactQuery/useIndexQuery';

import {
  FETCH_LIFESTYLE_CATEGORIES_QUERY,
  FetchLifestyleCategoriesQueryResponse
} from '../../queries/fetchLifestyleCategories.query';

import { LifestyleCache } from '../../LifestyleCache';

const defaultInitialFilters = {
  scope: ['scene_rooms']
};
const initialSort = ['NAME_ASC'];
const options = {
  staleTime: 1000 * 60 * 60
};

const scope = 'categories';

interface LifestyleCategoriesOptions {
  clientId?: LifestyleClientID;
}

function useLifestyleCategories({ clientId }: LifestyleCategoriesOptions = {}) {
  const cacheKey = clientId
    ? LifestyleCache.categoriesLibraryCacheKey(clientId)
    : LifestyleCache.categoriesCacheKey();
  const initialFilters = clientId
    ? { ...defaultInitialFilters, lifestyleClientIds: [clientId] }
    : defaultInitialFilters;

  const { items, itemsError, isFetched, isPlaceholderData } =
    useIndexQuery<FetchLifestyleCategoriesQueryResponse>({
      query: FETCH_LIFESTYLE_CATEGORIES_QUERY,
      cacheKey,
      initialFilters,
      initialSort,
      initialLimit: 1000,
      scope,
      options
    });

  return {
    lifestyleCategories: items,
    lifestyleCategoriesErrorMessage: itemsError,
    lifestyleCategoriesFetched: isFetched,
    lifestyleCategoriesIsPlaceholderData: isPlaceholderData
  };
}

export default useLifestyleCategories;
