import {
  LifestyleCategoryID,
  LifestyleUUID,
  LifestyleClientID
} from './lifestylesTypes';

export class LifestyleCache {
  static indexCacheKey() {
    return 'lifestyles';
  }

  static showCacheKey() {
    return 'lifestyle';
  }

  static categoriesCacheKey() {
    return 'lifestyleCategories';
  }

  static categoriesLibraryCacheKey(clientId: LifestyleClientID) {
    return `lifestyleCategoriesLibrary-${clientId}`;
  }

  static libraryCacheKey(clientId: LifestyleClientID) {
    return `lifestylesLibrary-${clientId}`;
  }

  static categoryCacheKey(id: LifestyleCategoryID) {
    return `lifestylesCategory-${id}`;
  }

  static stylesFilterCacheKey() {
    return 'lifestyleStylesFilter';
  }

  static stylesLibraryFilterCacheKey(clientId: LifestyleClientID) {
    return `lifestyleStylesLibraryFilter-${clientId}`;
  }

  static gammasFilterCacheKey() {
    return 'lifestyleGammasFilter';
  }

  static gammasLibraryFilterCacheKey(clientId: LifestyleClientID) {
    return `lifestyleGammasLibraryFilter-${clientId}`;
  }

  static tonesFilterCacheKey() {
    return 'lifestyleTonesFilter';
  }

  static tonesLibraryFilterCacheKey(clientId: LifestyleClientID) {
    return `lifestyleTonesLibraryFilter-${clientId}`;
  }

  static materialsFilterCacheKey() {
    return 'lifestyleMaterialsFilter';
  }

  static materialsLibraryFilterCacheKey(clientId: LifestyleClientID) {
    return `lifestyleMaterialsLibraryFilter-${clientId}`;
  }

  static colorsFilterCacheKey() {
    return 'lifestyleColorsFilter';
  }

  static colorsLibraryFilterCacheKey(clientId: LifestyleClientID) {
    return `lifestyleColorsLibraryFilter-${clientId}`;
  }

  static authorsFilterCacheKey() {
    return 'lifestyleAuthorFilter';
  }

  static authorsLibraryFilterCacheKey(clientId: LifestyleClientID) {
    return `lifestyleAuthorsLibraryFilter-${clientId}`;
  }

  static authorsSelectedFilterCacheKey() {
    return 'lifestyleAuthorsSelectedFilter';
  }

  static showQueryKey(lifestyleUuid: LifestyleUUID) {
    return ['lifestyle', lifestyleUuid];
  }
}
