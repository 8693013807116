import {
  BooleanFilter,
  CreatedAt,
  CreateItemGqlQuery,
  CreateItemGqlStatus,
  Currencies,
  CreateItemIsLoading,
  FetchItemCacheKey,
  FetchItemFetched,
  FetchItemGqlQuery,
  FetchItemIsPlaceholderData,
  FetchItemsCacheKey,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  ID,
  ItemGqlError,
  TextFilterType,
  UUID,
  UUIDFilter,
  UpdateItemGqlQuery,
  UpdateItemGqlStatus,
  UpdateItemIndexCacheKey,
  UpdateItemShowCacheKey,
  FetchItemErrorMessage,
  AssociationIDsFilter,
  AssociationUUIDsFilter,
  MoneyType,
  ModelBrand,
  NanoIDFilter,
  AssociationNanoIDsFilter,
  StringFilter,
  FetchItemsClearItemsFilters,
  FetchItemsFilterItems,
  DateFilterType,
  NumberFilterType,
  IdFilterType
} from '../../types';

import {
  ProjectCreatedAt,
  ProjectID,
  ProjectName,
  ProjectNanoID,
  ProjectUUID
} from '../projects/projectsTypes';
import { TaskID } from '../tasks/tasksTypes';

import { TransactionID } from '../transactions/transactionsTypes';
import {
  UserFullName,
  UserPayoneerCheckoutAddress,
  UserID,
  UserImageFile,
  UserImageUUID,
  UserNanoID,
  UserUUID
} from '../users/usersTypes';

import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../common/hooks/useTableCheckable';

export type BillID = ID;
export type BillUUID = UUID;
export type BillNanoID = ModelBrand<UUID, 'BillNanoID'>;
export type BillAmount = number;
export type BillCurrency = Currencies;
export type BillStatus = BillStatuses;
export type BillBody = string;
export type BillAllocableUser = string;
export type BillAccount = string;
export type BillButtons = string;
export type BillPayable = boolean;
export type BillPaymentForm = string;
export type BillPaymentUrl = string;
export type BillPaymentData = {
  [key: string]: { [key: string]: string };
};
export type BillCreatedAt = CreatedAt;
export type BillPayerID = UserID;
export type BillPayerUUID = UserUUID;
export type BillPayerNanoID = UserNanoID;
export type BillPayerFullName = UserFullName;
export type BillPayerPayoneerCheckoutAddress = UserPayoneerCheckoutAddress;
export type BillPayerImageUUID = UserImageUUID;
export type BillPayerImageFile = UserImageFile;
export type BillUserID = UserID;
export type BillUserUUID = UserUUID;
export type BillUserNanoID = UserNanoID;
export type BillUserFullName = UserFullName;
export type BillUserImageUUID = UserImageUUID;
export type BillUserImageFile = UserImageFile;

export type BillPayment = JSON;
export type BillType = string;
export type BillsMetaAmount = MoneyType;

export type BillTransactionTagID = TransactionID;
export type BillProjectID = ProjectID;
export type BillProjectUUID = ProjectUUID;
export type BillProjectNanoID = ProjectNanoID;
export type BillProjectName = ProjectName;
export type BillProjectCreatedAt = ProjectCreatedAt;
export type BillTaskID = TaskID;

export type BillSuccessProjectID = ProjectID;
export type BillSummaryProjectID = ProjectID;

export enum BillStatuses {
  NOT_PAID = 'not paid',
  PAID = 'paid',
  CANCELED = 'canceled',
  PAYING = 'paying'
}

export enum BillsFilterStatuses {
  NOT_PAID = 'non_paid',
  PAID = 'paid',
  CANCELED = 'canceled',
  PAYING = 'paying'
}

export enum BillAccounts {
  PAYONEER = 'payoneer'
}

export enum BillFields {
  ID = 'id',
  UUID = 'uuid',
  AMOUNT = 'amount',
  CURRENCY = 'currency',
  BODY = 'body',
  ALLOCABLE_USER = 'allocableUser',
  ACCOUNT = 'account',
  BUTTONS = 'buttons',
  TRANSACTION_TAG_ID = 'transactionTagId',
  CREATED_AT = 'createdAt'
}

export enum FetchBillsFilterFields {
  AMOUNT_CENTS = 'amountCents',
  CREATED_AT = 'createdAt',
  NAME = 'name',
  PAYER_ID = 'payerId.in',
  PROJECT_NAME = 'projectName',
  STATUS = 'status.in',
  USER_ID = 'userId.in'
}

export interface FetchBillsFilters {
  amountCents?: BillAmountCentsFilter;
  name?: TextFilterType;
  status?: BillStatusFilter;
  self?: BooleanFilter;
  notPaid?: BooleanFilter;
  projectUuid?: UUIDFilter;
  projectNanoId?: NanoIDFilter;
  createdAt?: BillCreatedAtFilter;
  taskMemberIds?: AssociationIDsFilter;
  taskMemberUuids?: AssociationUUIDsFilter;
  taskMemberNanoIds?: AssociationNanoIDsFilter;
  projectName?: BillProjectNameFilter;
  userId?: BillUserIdFilter;
  payerId?: BillPayerIdFilter;
}

export enum FetchBillsSortableFields {
  ID = 'id',
  AMOUNT = 'amount_cents',
  CREATED_AT = 'createdAt',
  ACCOUNT = 'account',
  STATUS = 'status'
}

export enum FetchBillsSortTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  AMOUNT_ASC = 'AMOUNT_ASC',
  AMOUNT_DESC = 'AMOUNT_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  ACCOUNT_ASC = 'ACCOUNT_ASC',
  ACCOUNT_DESC = 'ACCOUNT_DESC',
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC'
}

export type FetchBillsGqlQuery = FetchItemsGqlQuery;
export type FetchBillGqlQuery = FetchItemGqlQuery;
export type FetchBillErrorMessage = FetchItemErrorMessage;
export type CreateBillInProjectGqlQuery = CreateItemGqlQuery;
export type CreateBillInTaskGqlQuery = CreateItemGqlQuery;

export type CreateBillInProjectGqlStatus = CreateItemGqlStatus;
export type CreateBillInTaskGqlStatus = CreateItemGqlStatus;
export type BillGqlError = ItemGqlError;
export type UpdateBillGqlQuery = UpdateItemGqlQuery;
export type UpdateBillGqlStatus = UpdateItemGqlStatus;
export type UpdateBillIndexCacheKey = UpdateItemIndexCacheKey;
export type UpdateBillShowCacheKey = UpdateItemShowCacheKey;

export type FetchBillsCacheKey = FetchItemsCacheKey;
export type FetchBillCacheKey = FetchItemCacheKey;
export type FetchBillPaymentCacheKey = FetchItemCacheKey;

export type FetchBillsFetched = FetchItemsFetched;
export type FetchBillsErrorMessage = FetchItemsErrorMessage;
export type FetchBillsIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchBillsPage = FetchItemsPage;
export type FetchBillsLimit = FetchItemsLimit;
export type FetchBillsSort = FetchItemsSort; // FetchBillsSortTypes[];
export type FetchBillsTotalCount = FetchItemsTotalCount;
export type FetchBillsSortBills = FetchItemsSortItems;
export type FetchBillsPaginateBills = FetchItemsPaginateItems;
export type FetchBillsPrefetchBills = FetchItemsPrefetchItems;
export type FetchBillsShowBill = FetchItemsShowItem;
export type FetchBillsEditBill = FetchItemsEditItem;

export type FetchBillsChecked = Checked;
export type FetchBillsCheckedHash = CheckedHashItem;
export type FetchBillsOnSetCheckedIds = OnSetCheckedIds;
export type FetchBillsCheckedAll = CheckedAll;
export type FetchBillsOnCheckAll = OnCheckAll;

export type FetchBillFetched = FetchItemFetched;
export type FetchBillIsPlaceholderData = FetchItemIsPlaceholderData;

export type CreateBillIsLoading = CreateItemIsLoading;

export type CancelBillGqlQuery = UpdateBillGqlQuery;
export type CancelBillGqlStatus = UpdateBillGqlStatus;
export type CancelBillIndexCacheKey = UpdateBillIndexCacheKey;
export type CancelBillShowCacheKey = UpdateBillShowCacheKey;

export type CheckBillGqlQuery = UpdateBillGqlQuery;
export type CheckBillGqlStatus = UpdateBillGqlStatus;
export type CheckBillIndexCacheKey = UpdateBillIndexCacheKey;
export type CheckBillShowCacheKey = UpdateBillShowCacheKey;

export type BillProjectNameFilter = StringFilter;
export type BillCreatedAtFilter = DateFilterType;
export type BillAmountCentsFilter = NumberFilterType;
export type BillStatusFilter = TextFilterType;
export type BillUserIdFilter = IdFilterType;
export type BillPayerIdFilter = IdFilterType;

export type FetchBillsFiltersFunc = (nextFilters: FetchBillsFilters) => void;
export type FetchBillsFilterBills = FetchItemsFilterItems<FetchBillsFilters>;
export type FetchBillsClearBillsFilters = FetchItemsClearItemsFilters;

export type OnBillSuccessCountdownRedirect = () => void;
export type OnBillCancelCountdownRedirect = () => void;
export type OnBillSummaryCountdownRedirect = () => void;
