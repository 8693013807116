import {
  DateFilterType,
  FetchItemsCacheKey,
  FetchItemsClearItemsFilters,
  FetchItemsEditItem,
  FetchItemsErrorMessage,
  FetchItemsFetched,
  FetchItemsFilterItems,
  FetchItemsGqlQuery,
  FetchItemsIsPlaceholderData,
  FetchItemsLimit,
  FetchItemsPage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  FetchItemsShowItem,
  FetchItemsSort,
  FetchItemsSortItems,
  FetchItemsTotalCount,
  TextFilterType
} from '../../../types';

import {
  Checked,
  CheckedAll,
  CheckedHashItem,
  OnCheckAll,
  OnSetCheckedIds
} from '../../../common/hooks/useTableCheckable';

export interface FetchRolesFilters {
  name?: TextFilterType;
  createdAt?: DateFilterType;
}

export enum FetchRolesSortableFields {
  ID = 'id',
  NAME = 'name',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt'
}

export enum FetchRolesFilterFields {
  NAME = 'name',
  CREATED_AT = 'createdAt'
}

export enum FetchRolesSortTypes {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC'
}

export type FetchRolesGqlQuery = FetchItemsGqlQuery;

export type FetchRolesCacheKey = FetchItemsCacheKey;

export type FetchRolesFetched = FetchItemsFetched;
export type FetchRolesErrorMessage = FetchItemsErrorMessage;
export type FetchRolesIsPlaceholderData = FetchItemsIsPlaceholderData;
export type FetchRolesPage = FetchItemsPage;
export type FetchRolesLimit = FetchItemsLimit;
export type FetchRolesSort = FetchItemsSort; // FetchRolesSortTypes[];
export type FetchRolesTotalCount = FetchItemsTotalCount;
export type FetchRolesFilterRoles = FetchItemsFilterItems<FetchRolesFilters>;
export type FetchRolesClearRolesFilters = FetchItemsClearItemsFilters;
export type FetchRolesSortRoles = FetchItemsSortItems;
export type FetchRolesPaginateRoles = FetchItemsPaginateItems;
export type FetchRolesPrefetchRoles = FetchItemsPrefetchItems;

export type FetchRolesShowRole = FetchItemsShowItem;
export type FetchRolesEditRole = FetchItemsEditItem;

export type FetchRolesChecked = Checked;
export type FetchRolesCheckedHash = CheckedHashItem;
export type FetchRolesOnSetCheckedIds = OnSetCheckedIds;
export type FetchRolesCheckedAll = CheckedAll;
export type FetchRolesOnCheckAll = OnCheckAll;
